import _ from "lodash";
import ToastMixin from "../../../mixins/ToastMixin";
import moment from "moment";
import { config } from "../../../plugins/configuration";

export default {
  mixins: [ToastMixin],
  methods: {
    getLabelViaggio(viaggio) {
      if (viaggio && viaggio.tipoViaggio) {
        if (viaggio.tipoViaggio.toLowerCase() === "arrivo") {
          return `${this.viewNomeViaggio() ? viaggio.nome : viaggio.codice} - provenienza ${viaggio.nodoPartenza ? viaggio.nodoPartenza.codice : "N/A"}`;
        }
        return `${this.viewNomeViaggio() ? viaggio.nome : viaggio.codice} - destinazione ${viaggio.nodoArrivo ? viaggio.nodoArrivo.codice : "N/A"}`;
      }
      return "";
    },
    getUtiPercLength(wagonLength, utiLenght) {
      return (100 * utiLenght) / wagonLength;
    },
    calcUtiCompWidth(wagonSpatials, utiId, impexp) {
      return { width: (wagonSpatials[impexp.toLowerCase()][utiId]?.width || 0) + "%" };
    },
    calcUtiCompMargin(wagonSpatials, utiId, impexp) {
      return { "margin-left": (wagonSpatials[impexp.toLowerCase()][utiId]?.margin || 0) + "%" };
    },
    getExportUtiNumber(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.sumBy(
          this._.filter(convoyWagon.listUti, (u) => !u.remainOnBoardScarico && u.tipoMovimento.toLowerCase() === "export"),
          (u) => u.visitaUti.numeroColli
        );
      }
      return 0;
    },
    getCarroConvenzionaleColorExport(convoyWagon) {
      // var utiStateColor = config.rails.utiWagonStates.export.pianificato.color;
      var scaricate = this.getUnchargedExportUtiNumber(convoyWagon);
      var numeroUti = this.getExportUtiNumber(convoyWagon);
      if (numeroUti === 0) {
        return config.rails.utiWagonStates.export.pianificato.color;
      } else if (numeroUti === scaricate) {
        return config.rails.utiWagonStates.export.scaricato.color;
        //colore x carro scaricato completamente
      } else if (scaricate === 0) {
        return config.rails.utiWagonStates.export.caricato.color;
        //colore x carro non ancora scaricato
      } else {
        return config.rails.utiWagonStates.export.parzialmenteScaricato.color;
        //colore x carro parzialemnte scaricato
      }
    },
    getImportUtiNumber(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.sumBy(
          this._.filter(convoyWagon.listUti, (u) => !u.remainOnBoardScarico && u.tipoMovimento.toLowerCase() === "import"),
          (u) => u.visitaUti.numeroColli
        );
      }
      return 0;
    },
    getCarroConvenzionaleColorImport(convoyWagon) {
      var caricate = this.getUnchargedImportUtiNumber(convoyWagon);
      var numeroUti = this.getImportUtiNumber(convoyWagon); //convoyWagon.listUti.length;
      if (numeroUti === 0) {
        return config.rails.utiWagonStates.export.pianificato.color;
      } else if (numeroUti === caricate) {
        return config.rails.utiWagonStates.import.caricato.color;
        //colore x carro caricato completamente
      } else if (caricate === 0) {
        return config.rails.utiWagonStates.import.pianificato.color;
        //colore x carro non ancora caricato
      } else {
        return config.rails.utiWagonStates.import.parzialmenteCaricato.color;
        //colore x carro parzialemnte caricato
      }
    },
    getUtiStateColor(utiCarro) {
      var utiStateColor = config.rails.utiWagonStates.export.pianificato.color;
      if (utiCarro.tipoMovimento && utiCarro.stato) {
        const statusObj = config.rails.utiWagonStates[utiCarro.tipoMovimento.toLowerCase()][utiCarro.stato.toLowerCase()];
        utiStateColor = statusObj.color;
      }
      return utiStateColor;
    },
    getUtiFeaturesColor(utiCarro) {
      let isPericoloso = false;
      let isReefer = false;

      /* anche in altri punti del rail vince l'informazione su uti, anche se nulla*/
      // console.log("dangerousIndicator", utiCarro.visitaUti.id, utiCarro.visitaUti.dangerousIndicator);
      if (!!utiCarro.visitaUti.dangerousIndicator) {
        isPericoloso = utiCarro.visitaUti.dangerousIndicator;
      } else if (utiCarro.visitaUti.uti) {
        isPericoloso = !!utiCarro.visitaUti.uti.pericoloso;
      } else if (utiCarro.visitaUti.tipoUti) {
        isPericoloso = !!utiCarro.visitaUti.tipoUti.pericoloso;
      }

      // console.log("operationalReefer", utiCarro.visitaUti.id, utiCarro.visitaUti.operationalReefer);
      if (!!utiCarro.visitaUti.operationalReefer) {
        isReefer = utiCarro.visitaUti.operationalReefer;
      } else if (utiCarro.visitaUti.uti) {
        isReefer = !!utiCarro.visitaUti.uti.reefer;
      } else if (utiCarro.visitaUti.tipoUti) {
        isReefer = !!utiCarro.visitaUti.tipoUti.reefer;
      }

      const utiFeaturesColor = {};
      if (isPericoloso && isReefer) {
        utiFeaturesColor["background-image"] =
          "-webkit-linear-gradient(45deg, " + config.rails.utiWagonFeatures.imo.color + " 50%, " + config.rails.utiWagonFeatures.reefer.color + " 50%)";
      } else if (isPericoloso) {
        utiFeaturesColor["background"] = config.rails.utiWagonFeatures.imo.color;
      } else if (isReefer) {
        utiFeaturesColor["background"] = config.rails.utiWagonFeatures.reefer.color;
      } else {
        utiFeaturesColor["background"] = config.rails.utiWagonFeatures.nofeature.color;
      }

      return utiFeaturesColor;
    },
    getWagonFeatureColor(carroConvoglio, isDamaged, featuresShowing) {
      if (featuresShowing && carroConvoglio?.visitaCarro?.carro?.ribassato)
        return config.rails.wagonFeatures.lowered.vuetifycolor;
      else if (isDamaged)
        return config.rails.wagonFeatures.damaged.vuetifycolor;
      return null;
    },
    getTonsText() {
      return config.rails.utiWagonFeatures.tons.text;
    },
    getYardText(utiCarro) {
      if (utiCarro.areaScarico) {
        return utiCarro.areaScarico.substring(0, 3);
      }
      return "";
    },
    getUtiStateColorText(utiCarro) {
      var utiStateColorText = config.rails.utiWagonStates.export.pianificato.colorText;
      if (utiCarro.tipoMovimento && utiCarro.stato) {
        const statusObj = config.rails.utiWagonStates[utiCarro.tipoMovimento.toLowerCase()][utiCarro.stato.toLowerCase()];
        if (statusObj) {
          utiStateColorText = statusObj.colorText;
        }
      }
      return utiStateColorText;
    },
    getUtiStateColorTextByTipoStato(tipoMovimento, stato) {
      var utiStateColorText = config.rails.utiWagonStates.export.pianificato.colorText;
      if (tipoMovimento && stato) {
        const statusObj = config.rails.utiWagonStates[tipoMovimento.toLowerCase()][stato.toLowerCase()];
        utiStateColorText = statusObj.colorText;
      }
      return utiStateColorText;
    },
    calcBorder(idx) {
      return { "border-width": idx === 0 ? "0px 0px 1px 0px" : "0px 0px 1px 1px" };
    },
    getUnchargedImportUtiNumber(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.sumBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "import" && u.stato.toLowerCase() === "caricato"),
          (u) => u.visitaUti.numeroColli
        );
      }
      return 0;
    },
    getUnchargedExportUtiNumber(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.sumBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "export" && u.stato.toLowerCase() === "scaricato"),
          (u) => u.visitaUti.numeroColli
        );
      }
      return 0;
    },
    getChargedImportUtiNumber(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.sumBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "import" && u.stato.toLowerCase() === "caricato"),
          (u) => u.visitaUti.numeroColli
        );
      }
      return 0;
    },
    getChargedExportUtiNumber(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.sumBy(
          this._.filter(convoyWagon.listUti, (u) => !u.remainOnBoardScarico && u.tipoMovimento.toLowerCase() === "export" && u.stato.toLowerCase() === "caricato"),
          (u) => u.visitaUti.numeroColli
        );
      }
      return 0;
    },
    getOrderedImportUties(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.orderBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "import"),
          [(u) => u.posizione]
        );
      }
      return [];
    },
    getOrderedExportUties(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.orderBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "export"),
          [(u) => u.posizione]
        );
      }
      return [];
    },
    getRTOrderedImportUties(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.orderBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "import" && u.stato.toLowerCase() === "caricato"),
          [(u) => u.posizione]
        );
      }
      return [];
    },
    getRTOrderedExportUties(convoyWagon) {
      if (convoyWagon.listUti) {
        return this._.orderBy(
          this._.filter(convoyWagon.listUti, (u) => u.tipoMovimento.toLowerCase() === "export" && u.stato.toLowerCase() === "caricato"),
          [(u) => u.posizione]
        );
      }
      return [];
    },
    hasCosmosConnector() {
      if (process.env.VUE_APP_COSMOS_CONNECTOR) {
        if (process.env.VUE_APP_COSMOS_CONNECTOR === "true") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    viewNomeViaggio() {
      if (process.env.VUE_APP_CODICE_VIAGGIO) {
        if (process.env.VUE_APP_CODICE_VIAGGIO === "NOME") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    viewBtnStampaModello540_541() {
      if (process.env.VUE_APP_BTN_STAMPA_MODELLO_540_541) {
        if (process.env.VUE_APP_BTN_STAMPA_MODELLO_540_541 === "true") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    viewBtnStampaInterchangeMock() {
      if (process.env.VUE_APP_BTN_STAMPA_INTERCHANGE_MOCK) {
        if (process.env.VUE_APP_BTN_STAMPA_INTERCHANGE_MOCK === "true") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    viewBtnStampaListaTrenoAbbinamento() {
      if (process.env.VUE_APP_BTN_STAMPA_LISTA_TRENO_ABBINAMENTO) {
        if (process.env.VUE_APP_BTN_STAMPA_LISTA_TRENO_ABBINAMENTO === "true") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    viewBtnGeneraViaggi() {
      if (process.env.VUE_APP_BTN_GENERA_VIAGGI) {
        if (process.env.VUE_APP_BTN_GENERA_VIAGGI === "true") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getEnvironment() {
      if (process.env.VUE_APP_ENVIROMENT) {
        return process.env.VUE_APP_ENVIROMENT;
      }
    },
    async scaricaAnnullaScaricoUtiCarro(utiCarro, areaStoccaggio) {
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        //we do nothing;
        return;
      }
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/scaricaUtiCarro";
      const statoPrec = utiCarro.stato;
      var nuovoStato = null;
      if (statoPrec === "CANCELLATO") {
        nuovoStato = "CARICATO";
      } else if (statoPrec === "SCARICATO") {
        nuovoStato = "CARICATO";
      } else if (statoPrec === "CARICATO") {
        nuovoStato = "SCARICATO";
      }

      const reqObj = {
        utiCarroId: utiCarro.id,
        stato: nuovoStato,
        statoPrec: statoPrec,
        areaStoccaggio: areaStoccaggio,
      };
      try {
        var ret = await this.$api.post(fullPath, reqObj);
        console.log("scaricaAnnullaScaricoUtiCarro - ret", ret);
        utiCarro.stato = nuovoStato;

        return ret;
      } catch (e) {
        console.error(e);
        this.showError("Operazione non riuscita. " + e.message);
      }
    },
    displayUtiCarro(utiCarro) {
      const visitaUti = utiCarro.visitaUti;
      if (visitaUti.uti) return visitaUti.uti.codice;
      if (visitaUti.countType === "I") return `${visitaUti.merce} (${utiCarro.colli || ""})`;
      return visitaUti.merce;
    },
    viewBtnExportUtiList() {
      if (process.env.VUE_APP_BTN_ESPORTA_UTI_LIST) {
        if (process.env.VUE_APP_BTN_ESPORTA_UTI_LIST === "true") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getUtiColorStyle(utiCarro, featuresShowing) {
      return featuresShowing ? this.getUtiFeaturesColor(utiCarro) : { background: this.getUtiStateColor(utiCarro) };
    },
    calcWagonSpatials(carroConvoglio) {
      const res = {};
      let sum = {};
      const carroLgt = carroConvoglio?.visitaCarro?.carro?.lunghezza;
      carroConvoglio.listUti.map((u) => {

        const utiLgt = (u.visitaUti?.uti?.lunghezza || 
                        u.visitaUti?.tipoUti?.lunghezzaPiedi || 
                        u.visitaUti?.tipoUti?.lunghezzaEsterna) / 3.281;
        const utiLgtPerc = !!carroLgt && !!utiLgt ? (utiLgt / carroLgt) * 100 : 0;
        // let utiMrgPerc = 0;
        if (!sum[u.tipoMovimento.toLowerCase()]) sum[u.tipoMovimento.toLowerCase()] = 0;

        /* if (u.posizioneCarroConvoglio != 1) {
          let empty = 0;
          for (let i = 1; i<u.posizioneCarroConvoglio; i++) {
            if (!this.carroConvoglio.listUti.find(u2 => u2.posizioneCarroConvoglio == i && u2.tipoMovimento === u.tipoMovimento))
              empty++;
          }
          utiMrgPerc = !!carroLgt ? ((20/3.281 * empty) / carroLgt)*100 : 0;
        } */

        if (!res[u.tipoMovimento.toLowerCase()]) res[u.tipoMovimento.toLowerCase()] = {};

        res[u.tipoMovimento.toLowerCase()][u.id] = {
          width: utiLgtPerc,
          // margin: utiMrgPerc
        };

        sum[u.tipoMovimento.toLowerCase()] += utiLgtPerc; // + utiMrgPerc;
      });

      for (const [k, tipoMov] of Object.entries(res)) {
        if (sum[k] > 100) {
          const ratio = 100 / sum[k];
          for (const [key, value] of Object.entries(tipoMov)) {
            res[k][key] = {
              width: value.width * ratio,
              // margin: value.margin * ratio
            };
          }
          res[k]["warn"] = true;
        }
      }

      return res;
    },
  },
  filters: {
    formatDate(date) {
      if (!date) return "N/A";
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateTime(date) {
      if (!date) return "N/A";
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    formatDateTimeNoYear(date) {
      if (!date) return "N/A";
      return moment(date).format("DD/MM HH:mm");
    },
    isoFormatDate(date) {
      if (!date) return "N/A";
      return moment(date).format("YYYY-MM-DD");
    },
    timeFromDate(date) {
      if (!date) return "N/A";
      return moment(date).format("HH:mm");
    },
    timeFormatFromTs(ts) {
      return moment(+ts).format("HH:mm");
    },
    tsFromDateFormat(dateFormat) {
      if (!dateFormat) return "N/A";
      return moment(dateFormat).format("x");
    },
  },
  computed: {},
};
